* {
  margin: 0;
  padding: 0;
  border: 0;
  vertical-align: baseline;
  font-weight: 500;
  line-height: 1.5;
  text-decoration: none;
  box-sizing: border-box;
}
body {
  font-family: "Pretendard";
  -ms-overflow-style: none;
}
ol,
ul {
  list-style: none;
}
button {
  border: none;
  cursor: pointer;
  background: none;
}

/* 공통 css */
.content-wrap {
  width: calc(100vw - 190px);
  background-color: #eff1f6;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}
